import axios from "axios";

const CallAuthApi = (callback) => {
  var obj = {};
  obj.username = "root";
  obj.password = "Qb2022$1";
  var config = {
    method: "post",
    url: process.env.REACT_APP_API_AUTH_URL,
    header: { "Content-Type": "application/json" },
    data: obj,
  };
  axios(config)
    .then((res) => {
      callback(res.data.jwt);
      // return res.jwt;
    })
    .catch((err) => {
      console.error(err);
    });

  // return "";
};

const GetQueryInfo = (query, callbackfun) => {
  CallAuthApi(function (jwtresp) {
    var obj = {};
    obj.query = query;
    var config = {
      method: "post",
      url: "http://164.52.210.54:8080/api/read_qdmqueries",
      headers: { Authorization: `Bearer ${jwtresp}` },
      data: obj,
    };
    axios(config)
      .then((res) => {
        callbackfun(res);
        //return res;
      })
      .catch((err) => {
        console.error(err);
      });
  });
};

export default GetQueryInfo;
